import React, { useState, Suspense } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Section } from 'react-simple-resizer';
import ReactDOM from 'react-dom';
import { closeStore, openTabStore } from '../hooks/useCloseStore';
import { browseSearchToggle } from '../hooks/useBrowseSearchToggle';
//import { AdvancedToggle } from './MainSearchToggle/AdvancedToggle';
import './RightSideBar.scss';
import MandalaSkeleton from '../views/common/MandalaSkeleton';
import $ from 'jquery';

const TreeNav = React.lazy(() => import('./TreeNav'));
const SearchAdvanced = React.lazy(() => import('../search/SearchAdvanced'));

const target = document.getElementById('advancedSearchPortal');

export default function RightSideBar() {
    // Get Close Button state.
    // const closeButton = closeStore((state) => state.buttonState);

    const openTab = openTabStore((state) => state.openTab);
    const browseSearchState = browseSearchToggle((state) => state.browseSearch);
    const setSearch = browseSearchToggle((state) => state.setSearch);
    const setBrowse = browseSearchToggle((state) => state.setBrowse);

    // Get the baseType for the route.
    const match = useRouteMatch([
        '/:baseType/:id/related-:type/:definitionID/view/:relID',
        '/:baseType/:id/related-:type/:definitionID/:viewMode',
        '/:baseType/:id/related-:type',
        '/:baseType/:id',
    ]);
    const baseType = match?.params.baseType || 'none';
    const browseSearch =
        ['terms', 'places', 'subjects'].includes(baseType) || openTab == 2
            ? 'browse'
            : 'search';

    React.useEffect(() => {
        if (browseSearch !== browseSearchState) {
            switch (browseSearchState) {
                case 'browse':
                    setBrowse(); // changes browseSearchState to "browse"
                    break;
                case 'search':
                    setSearch(); // changes browseSearchState to "search"
                    break;
                default:
                    break;
            }
        }
        if (openTab === 0) {
            $('#secondary, .l-content__rightsidebar').hide();
            $('.bottom-deck').hide(); // .bottom-deck is used in THL site. Added with custom JS. Other sites can use or hid.
        } else {
            $('#secondary, .l-content__rightsidebar').show();
            $('.bottom-deck').show(); // Used in THL site. Added with custom JS. Other sites can use or hid.
            // mandala_wp.navmenu is the ID of the WP navigation menu put in the sidebare. See WP Plugin, mandala.js file
            if (window.mandala_wp?.navmenu) {
                const navmenu_id = '#' + window.mandala_wp.navmenu;
                if (openTab === 3) {
                    $(navmenu_id).show();
                } else {
                    $(navmenu_id).hide();
                }
            }
        }
    }, [openTab]);

    // openTab === 3 is an empty menu tab, which must be activated by an env variable: REACT_APP_DISPLAY_MENU_TOGGLE=true
    // allows WP embeds to have a menu button and place the menu in the side bar.
    // WP styles must be used to show and hide the menu
    const advancedSearchPortal =
        openTab === 3 ? (
            <div
                className="l-content__rightsidebar closeSideBar sidebar-0"
                style={{
                    overflow: 'hidden',
                    maxWidth: '580px',
                    minWidth: '350px',
                    flex: '0 0 380px',
                    display: 'none',
                }}
            ></div>
        ) : (
            <Section
                className={`l-content__rightsidebar ${
                    //closeButton ? 'openSideBar' : 'closeSideBar'
                    openTab > 0 ? 'openSideBar' : 'closeSideBar'
                } sidebar-${openTab}`}
                maxSize={580}
                minSize={350}
                defaultSize={380}
            >
                <div className="advanced-search-and-tree">
                    {browseSearchState === 'search' && (
                        <Suspense fallback={<MandalaSkeleton />}>
                            <SearchAdvanced advanced={true} />
                        </Suspense>
                    )}
                    {browseSearchState === 'browse' && (
                        <Suspense fallback={<MandalaSkeleton />}>
                            <TreeNav tree={true} />
                        </Suspense>
                    )}
                </div>
            </Section>
        );

    if (target) {
        return ReactDOM.createPortal(advancedSearchPortal, target);
    } else {
        return advancedSearchPortal;
    }
}
